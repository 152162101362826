exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bug-bounty-index-tsx": () => import("./../../../src/pages/bug-bounty/index.tsx" /* webpackChunkName: "component---src-pages-bug-bounty-index-tsx" */),
  "component---src-pages-bug-bounty-rewards-tsx": () => import("./../../../src/pages/bug-bounty/rewards.tsx" /* webpackChunkName: "component---src-pages-bug-bounty-rewards-tsx" */),
  "component---src-pages-bug-bounty-vulnerabilities-types-index-tsx": () => import("./../../../src/pages/bug-bounty/vulnerabilities-types/index.tsx" /* webpackChunkName: "component---src-pages-bug-bounty-vulnerabilities-types-index-tsx" */),
  "component---src-pages-careers-back-end-index-tsx": () => import("./../../../src/pages/careers/back-end/index.tsx" /* webpackChunkName: "component---src-pages-careers-back-end-index-tsx" */),
  "component---src-pages-careers-besquare-index-tsx": () => import("./../../../src/pages/careers/besquare/index.tsx" /* webpackChunkName: "component---src-pages-careers-besquare-index-tsx" */),
  "component---src-pages-careers-business-excellence-index-tsx": () => import("./../../../src/pages/careers/business-excellence/index.tsx" /* webpackChunkName: "component---src-pages-careers-business-excellence-index-tsx" */),
  "component---src-pages-careers-business-intelligence-index-tsx": () => import("./../../../src/pages/careers/business-intelligence/index.tsx" /* webpackChunkName: "component---src-pages-careers-business-intelligence-index-tsx" */),
  "component---src-pages-careers-customer-support-index-tsx": () => import("./../../../src/pages/careers/customer-support/index.tsx" /* webpackChunkName: "component---src-pages-careers-customer-support-index-tsx" */),
  "component---src-pages-careers-cyber-security-it-index-tsx": () => import("./../../../src/pages/careers/cyber-security-it/index.tsx" /* webpackChunkName: "component---src-pages-careers-cyber-security-it-index-tsx" */),
  "component---src-pages-careers-finance-accounts-index-tsx": () => import("./../../../src/pages/careers/finance-accounts/index.tsx" /* webpackChunkName: "component---src-pages-careers-finance-accounts-index-tsx" */),
  "component---src-pages-careers-front-end-index-tsx": () => import("./../../../src/pages/careers/front-end/index.tsx" /* webpackChunkName: "component---src-pages-careers-front-end-index-tsx" */),
  "component---src-pages-careers-human-resources-index-tsx": () => import("./../../../src/pages/careers/human-resources/index.tsx" /* webpackChunkName: "component---src-pages-careers-human-resources-index-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-careers-internal-audit-index-tsx": () => import("./../../../src/pages/careers/internal-audit/index.tsx" /* webpackChunkName: "component---src-pages-careers-internal-audit-index-tsx" */),
  "component---src-pages-careers-legal-compliance-index-tsx": () => import("./../../../src/pages/careers/legal-compliance/index.tsx" /* webpackChunkName: "component---src-pages-careers-legal-compliance-index-tsx" */),
  "component---src-pages-careers-locations-asuncion-index-tsx": () => import("./../../../src/pages/careers/locations/asuncion/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-asuncion-index-tsx" */),
  "component---src-pages-careers-locations-berlin-index-tsx": () => import("./../../../src/pages/careers/locations/berlin/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-berlin-index-tsx" */),
  "component---src-pages-careers-locations-ciudad-index-tsx": () => import("./../../../src/pages/careers/locations/ciudad/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-ciudad-index-tsx" */),
  "component---src-pages-careers-locations-cyberjaya-index-tsx": () => import("./../../../src/pages/careers/locations/cyberjaya/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-cyberjaya-index-tsx" */),
  "component---src-pages-careers-locations-cyprus-index-tsx": () => import("./../../../src/pages/careers/locations/cyprus/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-cyprus-index-tsx" */),
  "component---src-pages-careers-locations-dubai-index-tsx": () => import("./../../../src/pages/careers/locations/dubai/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-dubai-index-tsx" */),
  "component---src-pages-careers-locations-georgetown-index-tsx": () => import("./../../../src/pages/careers/locations/georgetown/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-georgetown-index-tsx" */),
  "component---src-pages-careers-locations-guernsey-index-tsx": () => import("./../../../src/pages/careers/locations/guernsey/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-guernsey-index-tsx" */),
  "component---src-pages-careers-locations-index-tsx": () => import("./../../../src/pages/careers/locations/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-index-tsx" */),
  "component---src-pages-careers-locations-ipoh-index-tsx": () => import("./../../../src/pages/careers/locations/ipoh/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-ipoh-index-tsx" */),
  "component---src-pages-careers-locations-jordan-index-tsx": () => import("./../../../src/pages/careers/locations/jordan/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-jordan-index-tsx" */),
  "component---src-pages-careers-locations-labuan-index-tsx": () => import("./../../../src/pages/careers/locations/labuan/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-labuan-index-tsx" */),
  "component---src-pages-careers-locations-malta-index-tsx": () => import("./../../../src/pages/careers/locations/malta/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-malta-index-tsx" */),
  "component---src-pages-careers-locations-melaka-index-tsx": () => import("./../../../src/pages/careers/locations/melaka/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-melaka-index-tsx" */),
  "component---src-pages-careers-locations-minsk-index-tsx": () => import("./../../../src/pages/careers/locations/minsk/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-minsk-index-tsx" */),
  "component---src-pages-careers-locations-paris-index-tsx": () => import("./../../../src/pages/careers/locations/paris/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-paris-index-tsx" */),
  "component---src-pages-careers-locations-reading-index-tsx": () => import("./../../../src/pages/careers/locations/reading/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-reading-index-tsx" */),
  "component---src-pages-careers-locations-roadtown-index-tsx": () => import("./../../../src/pages/careers/locations/roadtown/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-roadtown-index-tsx" */),
  "component---src-pages-careers-locations-rwanda-index-tsx": () => import("./../../../src/pages/careers/locations/rwanda/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-rwanda-index-tsx" */),
  "component---src-pages-careers-locations-singapore-index-tsx": () => import("./../../../src/pages/careers/locations/singapore/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-singapore-index-tsx" */),
  "component---src-pages-careers-locations-vanuatu-index-tsx": () => import("./../../../src/pages/careers/locations/vanuatu/index.tsx" /* webpackChunkName: "component---src-pages-careers-locations-vanuatu-index-tsx" */),
  "component---src-pages-careers-marketing-index-tsx": () => import("./../../../src/pages/careers/marketing/index.tsx" /* webpackChunkName: "component---src-pages-careers-marketing-index-tsx" */),
  "component---src-pages-careers-payment-solutions-index-tsx": () => import("./../../../src/pages/careers/payment-solutions/index.tsx" /* webpackChunkName: "component---src-pages-careers-payment-solutions-index-tsx" */),
  "component---src-pages-careers-product-design-index-tsx": () => import("./../../../src/pages/careers/product-design/index.tsx" /* webpackChunkName: "component---src-pages-careers-product-design-index-tsx" */),
  "component---src-pages-careers-project-management-index-tsx": () => import("./../../../src/pages/careers/project-management/index.tsx" /* webpackChunkName: "component---src-pages-careers-project-management-index-tsx" */),
  "component---src-pages-careers-software-testing-index-tsx": () => import("./../../../src/pages/careers/software-testing/index.tsx" /* webpackChunkName: "component---src-pages-careers-software-testing-index-tsx" */),
  "component---src-pages-careers-trading-operations-index-tsx": () => import("./../../../src/pages/careers/trading-operations/index.tsx" /* webpackChunkName: "component---src-pages-careers-trading-operations-index-tsx" */),
  "component---src-pages-check-email-index-tsx": () => import("./../../../src/pages/check-email/index.tsx" /* webpackChunkName: "component---src-pages-check-email-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact_us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-dbot-index-tsx": () => import("./../../../src/pages/dbot/index.tsx" /* webpackChunkName: "component---src-pages-dbot-index-tsx" */),
  "component---src-pages-deriv-go-index-tsx": () => import("./../../../src/pages/deriv-go/index.tsx" /* webpackChunkName: "component---src-pages-deriv-go-index-tsx" */),
  "component---src-pages-derivez-index-tsx": () => import("./../../../src/pages/derivez/index.tsx" /* webpackChunkName: "component---src-pages-derivez-index-tsx" */),
  "component---src-pages-derivez-what-derivez-have-tsx": () => import("./../../../src/pages/derivez/what-derivez-have.tsx" /* webpackChunkName: "component---src-pages-derivez-what-derivez-have-tsx" */),
  "component---src-pages-derivx-index-tsx": () => import("./../../../src/pages/derivx/index.tsx" /* webpackChunkName: "component---src-pages-derivx-index-tsx" */),
  "component---src-pages-dmt-5-index-tsx": () => import("./../../../src/pages/dmt5/index.tsx" /* webpackChunkName: "component---src-pages-dmt-5-index-tsx" */),
  "component---src-pages-dmt-5-trading-signals-index-tsx": () => import("./../../../src/pages/dmt5-trading-signals/index.tsx" /* webpackChunkName: "component---src-pages-dmt-5-trading-signals-index-tsx" */),
  "component---src-pages-dtrader-index-tsx": () => import("./../../../src/pages/dtrader/index.tsx" /* webpackChunkName: "component---src-pages-dtrader-index-tsx" */),
  "component---src-pages-endpoint-index-tsx": () => import("./../../../src/pages/endpoint/index.tsx" /* webpackChunkName: "component---src-pages-endpoint-index-tsx" */),
  "component---src-pages-help-centre-about-deriv-tsx": () => import("./../../../src/pages/help-centre/about-deriv.tsx" /* webpackChunkName: "component---src-pages-help-centre-about-deriv-tsx" */),
  "component---src-pages-help-centre-account-tsx": () => import("./../../../src/pages/help-centre/account.tsx" /* webpackChunkName: "component---src-pages-help-centre-account-tsx" */),
  "component---src-pages-help-centre-affiliate-programme-tsx": () => import("./../../../src/pages/help-centre/affiliate-programme.tsx" /* webpackChunkName: "component---src-pages-help-centre-affiliate-programme-tsx" */),
  "component---src-pages-help-centre-dbot-tsx": () => import("./../../../src/pages/help-centre/dbot.tsx" /* webpackChunkName: "component---src-pages-help-centre-dbot-tsx" */),
  "component---src-pages-help-centre-deposits-and-withdrawals-tsx": () => import("./../../../src/pages/help-centre/deposits-and-withdrawals.tsx" /* webpackChunkName: "component---src-pages-help-centre-deposits-and-withdrawals-tsx" */),
  "component---src-pages-help-centre-deriv-ez-tsx": () => import("./../../../src/pages/help-centre/deriv-ez.tsx" /* webpackChunkName: "component---src-pages-help-centre-deriv-ez-tsx" */),
  "component---src-pages-help-centre-deriv-p-2-p-tsx": () => import("./../../../src/pages/help-centre/deriv-p2p.tsx" /* webpackChunkName: "component---src-pages-help-centre-deriv-p-2-p-tsx" */),
  "component---src-pages-help-centre-deriv-x-tsx": () => import("./../../../src/pages/help-centre/deriv-x.tsx" /* webpackChunkName: "component---src-pages-help-centre-deriv-x-tsx" */),
  "component---src-pages-help-centre-dmt-5-tsx": () => import("./../../../src/pages/help-centre/dmt5.tsx" /* webpackChunkName: "component---src-pages-help-centre-dmt-5-tsx" */),
  "component---src-pages-help-centre-dtrader-tsx": () => import("./../../../src/pages/help-centre/dtrader.tsx" /* webpackChunkName: "component---src-pages-help-centre-dtrader-tsx" */),
  "component---src-pages-help-centre-ib-programme-tsx": () => import("./../../../src/pages/help-centre/ib-programme.tsx" /* webpackChunkName: "component---src-pages-help-centre-ib-programme-tsx" */),
  "component---src-pages-help-centre-index-tsx": () => import("./../../../src/pages/help-centre/index.tsx" /* webpackChunkName: "component---src-pages-help-centre-index-tsx" */),
  "component---src-pages-help-centre-payment-agent-tsx": () => import("./../../../src/pages/help-centre/payment-agent.tsx" /* webpackChunkName: "component---src-pages-help-centre-payment-agent-tsx" */),
  "component---src-pages-help-centre-security-tsx": () => import("./../../../src/pages/help-centre/security.tsx" /* webpackChunkName: "component---src-pages-help-centre-security-tsx" */),
  "component---src-pages-help-centre-trading-tsx": () => import("./../../../src/pages/help-centre/trading.tsx" /* webpackChunkName: "component---src-pages-help-centre-trading-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-interim-affiliate-index-tsx": () => import("./../../../src/pages/interim/affiliate/index.tsx" /* webpackChunkName: "component---src-pages-interim-affiliate-index-tsx" */),
  "component---src-pages-interim-dbot-index-tsx": () => import("./../../../src/pages/interim/dbot/index.tsx" /* webpackChunkName: "component---src-pages-interim-dbot-index-tsx" */),
  "component---src-pages-interim-deriv-index-tsx": () => import("./../../../src/pages/interim/deriv/index.tsx" /* webpackChunkName: "component---src-pages-interim-deriv-index-tsx" */),
  "component---src-pages-interim-dmt-5-index-tsx": () => import("./../../../src/pages/interim/dmt5/index.tsx" /* webpackChunkName: "component---src-pages-interim-dmt-5-index-tsx" */),
  "component---src-pages-interim-faq-index-tsx": () => import("./../../../src/pages/interim/faq/index.tsx" /* webpackChunkName: "component---src-pages-interim-faq-index-tsx" */),
  "component---src-pages-landing-dmt-5-index-tsx": () => import("./../../../src/pages/landing/dmt5/index.tsx" /* webpackChunkName: "component---src-pages-landing-dmt-5-index-tsx" */),
  "component---src-pages-landing-ebooks-crypto-tsx": () => import("./../../../src/pages/landing/ebooks/crypto.tsx" /* webpackChunkName: "component---src-pages-landing-ebooks-crypto-tsx" */),
  "component---src-pages-landing-ebooks-forex-tsx": () => import("./../../../src/pages/landing/ebooks/forex.tsx" /* webpackChunkName: "component---src-pages-landing-ebooks-forex-tsx" */),
  "component---src-pages-landing-ebooks-stocks-tsx": () => import("./../../../src/pages/landing/ebooks/stocks.tsx" /* webpackChunkName: "component---src-pages-landing-ebooks-stocks-tsx" */),
  "component---src-pages-landing-forex-trading-index-tsx": () => import("./../../../src/pages/landing/forex-trading/index.tsx" /* webpackChunkName: "component---src-pages-landing-forex-trading-index-tsx" */),
  "component---src-pages-landing-index-tsx": () => import("./../../../src/pages/landing/index.tsx" /* webpackChunkName: "component---src-pages-landing-index-tsx" */),
  "component---src-pages-landing-jump-indices-index-tsx": () => import("./../../../src/pages/landing/jump-indices/index.tsx" /* webpackChunkName: "component---src-pages-landing-jump-indices-index-tsx" */),
  "component---src-pages-landing-signup-index-tsx": () => import("./../../../src/pages/landing/signup/index.tsx" /* webpackChunkName: "component---src-pages-landing-signup-index-tsx" */),
  "component---src-pages-landing-stocks-index-tsx": () => import("./../../../src/pages/landing/stocks/index.tsx" /* webpackChunkName: "component---src-pages-landing-stocks-index-tsx" */),
  "component---src-pages-landing-trade-forex-index-tsx": () => import("./../../../src/pages/landing/trade-forex/index.tsx" /* webpackChunkName: "component---src-pages-landing-trade-forex-index-tsx" */),
  "component---src-pages-landing-weekend-trading-index-tsx": () => import("./../../../src/pages/landing/weekend-trading/index.tsx" /* webpackChunkName: "component---src-pages-landing-weekend-trading-index-tsx" */),
  "component---src-pages-livechat-index-tsx": () => import("./../../../src/pages/livechat/index.tsx" /* webpackChunkName: "component---src-pages-livechat-index-tsx" */),
  "component---src-pages-markets-basket-indices-index-tsx": () => import("./../../../src/pages/markets/basket-indices/index.tsx" /* webpackChunkName: "component---src-pages-markets-basket-indices-index-tsx" */),
  "component---src-pages-markets-commodities-index-tsx": () => import("./../../../src/pages/markets/commodities/index.tsx" /* webpackChunkName: "component---src-pages-markets-commodities-index-tsx" */),
  "component---src-pages-markets-cryptocurrencies-index-tsx": () => import("./../../../src/pages/markets/cryptocurrencies/index.tsx" /* webpackChunkName: "component---src-pages-markets-cryptocurrencies-index-tsx" */),
  "component---src-pages-markets-derived-fx-index-tsx": () => import("./../../../src/pages/markets/derived-fx/index.tsx" /* webpackChunkName: "component---src-pages-markets-derived-fx-index-tsx" */),
  "component---src-pages-markets-forex-index-tsx": () => import("./../../../src/pages/markets/forex/index.tsx" /* webpackChunkName: "component---src-pages-markets-forex-index-tsx" */),
  "component---src-pages-markets-stock-index-tsx": () => import("./../../../src/pages/markets/stock/index.tsx" /* webpackChunkName: "component---src-pages-markets-stock-index-tsx" */),
  "component---src-pages-markets-synthetic-index-tsx": () => import("./../../../src/pages/markets/synthetic/index.tsx" /* webpackChunkName: "component---src-pages-markets-synthetic-index-tsx" */),
  "component---src-pages-our-principles-index-tsx": () => import("./../../../src/pages/our-principles/index.tsx" /* webpackChunkName: "component---src-pages-our-principles-index-tsx" */),
  "component---src-pages-p-2-p-index-tsx": () => import("./../../../src/pages/p2p/index.tsx" /* webpackChunkName: "component---src-pages-p-2-p-index-tsx" */),
  "component---src-pages-partners-affiliate-ib-index-tsx": () => import("./../../../src/pages/partners/affiliate-ib/index.tsx" /* webpackChunkName: "component---src-pages-partners-affiliate-ib-index-tsx" */),
  "component---src-pages-partners-index-tsx": () => import("./../../../src/pages/partners/index.tsx" /* webpackChunkName: "component---src-pages-partners-index-tsx" */),
  "component---src-pages-partners-payment-agent-index-tsx": () => import("./../../../src/pages/partners/payment-agent/index.tsx" /* webpackChunkName: "component---src-pages-partners-payment-agent-index-tsx" */),
  "component---src-pages-payment-methods-index-tsx": () => import("./../../../src/pages/payment-methods/index.tsx" /* webpackChunkName: "component---src-pages-payment-methods-index-tsx" */),
  "component---src-pages-regulatory-index-tsx": () => import("./../../../src/pages/regulatory/index.tsx" /* webpackChunkName: "component---src-pages-regulatory-index-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-responsible-index-tsx": () => import("./../../../src/pages/responsible/index.tsx" /* webpackChunkName: "component---src-pages-responsible-index-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-signup-success-index-tsx": () => import("./../../../src/pages/signup-success/index.tsx" /* webpackChunkName: "component---src-pages-signup-success-index-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-trade-types-cfds-index-tsx": () => import("./../../../src/pages/trade-types/cfds/index.tsx" /* webpackChunkName: "component---src-pages-trade-types-cfds-index-tsx" */),
  "component---src-pages-trade-types-multiplier-index-tsx": () => import("./../../../src/pages/trade-types/multiplier/index.tsx" /* webpackChunkName: "component---src-pages-trade-types-multiplier-index-tsx" */),
  "component---src-pages-trade-types-options-index-tsx": () => import("./../../../src/pages/trade-types/options/index.tsx" /* webpackChunkName: "component---src-pages-trade-types-options-index-tsx" */),
  "component---src-pages-trader-tools-index-tsx": () => import("./../../../src/pages/trader-tools/index.tsx" /* webpackChunkName: "component---src-pages-trader-tools-index-tsx" */),
  "component---src-pages-trader-tools-margin-calculator-index-tsx": () => import("./../../../src/pages/trader-tools/margin-calculator/index.tsx" /* webpackChunkName: "component---src-pages-trader-tools-margin-calculator-index-tsx" */),
  "component---src-pages-trader-tools-pip-calculator-index-tsx": () => import("./../../../src/pages/trader-tools/pip-calculator/index.tsx" /* webpackChunkName: "component---src-pages-trader-tools-pip-calculator-index-tsx" */),
  "component---src-pages-trader-tools-pnl-for-margin-index-tsx": () => import("./../../../src/pages/trader-tools/pnl-for-margin/index.tsx" /* webpackChunkName: "component---src-pages-trader-tools-pnl-for-margin-index-tsx" */),
  "component---src-pages-trader-tools-pnl-for-multipliers-index-tsx": () => import("./../../../src/pages/trader-tools/pnl-for-multipliers/index.tsx" /* webpackChunkName: "component---src-pages-trader-tools-pnl-for-multipliers-index-tsx" */),
  "component---src-pages-trader-tools-swap-calculator-index-tsx": () => import("./../../../src/pages/trader-tools/swap-calculator/index.tsx" /* webpackChunkName: "component---src-pages-trader-tools-swap-calculator-index-tsx" */),
  "component---src-pages-unsubscribe-index-tsx": () => import("./../../../src/pages/unsubscribe/index.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-tsx" */),
  "component---src-pages-who-we-are-index-tsx": () => import("./../../../src/pages/who-we-are/index.tsx" /* webpackChunkName: "component---src-pages-who-we-are-index-tsx" */),
  "component---src-pages-why-choose-us-index-tsx": () => import("./../../../src/pages/why-choose-us/index.tsx" /* webpackChunkName: "component---src-pages-why-choose-us-index-tsx" */)
}

